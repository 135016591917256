import { useEffect, Fragment, useState } from "react";

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import Button from "@mui/material/Button";

import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";

import { LoadingPage } from "./LoadingPage";
import { CoverEvaluation } from "./CoverEvaluation";
import { ItemEvaluation } from "./ItemAvaluation";

import { addBreakPage } from "utils/print";
import { orderArrayById } from "utils/orderArrayById";

import {
  AssessmentFormData,
  Exam,
  SelectedElement,
  Item,
  Task,
} from "services/admin/Interfaces/Types";

import { MarkdownRender } from "lib_editor";
import {
  T1,
  T2,
  T3,
  T4,
  T5,
  Dica,
  Observacao,
  Audio,
  Atividade,
  Video,
  Explanation,
  Carrossel,
  Equacao,
  CodeHighlighter,
} from "lib_editor";
import "lib_editor/dist/style.css";

import "./styles.css";
import TaskServices from "store/reducers/Admin/AsyncActions/Tasks/task.service";
import { AxiosResponse } from "axios";

interface IExamsForAssessment {
  id_assessment: number;
  id_exam: number;
  list_tasks: {
    id: number;
    title: string;
    content: string;
    created_at: string;
    created_by: number;
    items: Item[];
    subcontents: {
      content: string;
      id: number;
      position: number;
      task: number;
    }[];
  }[];
}

const components = {
  ex: Explanation,
  dica: Dica,
  obs: Observacao,
  au: Audio,
  atv: Atividade,
  video: Video,
  equacao: Equacao,
  code: CodeHighlighter,
  carrossel: Carrossel,
  h1: T1,
  h2: T2,
  h3: T3,
  h4: T4,
  h5: T5,
};

export function PrintAvaluation() {
  let numberItem = 0;
  const navigate = useHistory();

  const selectedElement: SelectedElement = useSelector(
    (state: any) => state.admin
  ).selectedElement;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;
  const [tasks, setTasks] = useState<Task[]>([] as Task[]);

  const [isLoading, setIsLoading] = useState(true);
  const [assessment, setAssessment] = useState({
    title_assessment: "",
    description_assessment: "",
  });
  const [examsForAssessment, setExamsFormAssessment] = useState<
    IExamsForAssessment[]
  >([]);

  async function generateExamsData() {
    try {
      const dataAssessment: AssessmentFormData = {
        ...selectedElement.elementDataForm,
      } as AssessmentFormData;

      if (dataAssessment.exams.length > 0) {
        const list_exams = await orderArrayById(dataAssessment.exams).map(
          (exam) => {
            const getDataExam = exams.find(
              (exam_obj) => exam_obj.id === exam.exam
            );

            const list_tasks = orderArrayById(getDataExam!.tasks).map(
              (task) => {
                const data_task = tasks.find(
                  (task_obj) => task_obj.id === task.task
                );

                return data_task;
              }
            );

            return {
              id_assessment: dataAssessment.id,
              id_exam: getDataExam?.id,
              list_tasks,
            };
          }
        );

        setAssessment({
          title_assessment: dataAssessment.title,
          description_assessment: dataAssessment.description,
        });
        setExamsFormAssessment(list_exams);

        return {
          status: "success",
          code_assessment: dataAssessment.code,
        };
      }
    } catch {
      return {
        status: "error",
        code_assessment: null,
        error: "Ocorreu um erro ao gerar os exams",
      };
    }
  }

  useEffect(() => {
    let promises: Promise<AxiosResponse<Task[]>>[] = [];
    (selectedElement.elementDataForm as AssessmentFormData).exams.forEach((e) =>
      promises.push(TaskServices.getAllTasks({ exam_id: e.exam }))
    );

    Promise.all(promises).then((response) => {
      let tasksToBeAdded: Task[] = tasks;
      response
        .flatMap((r) => r.data["results"])
        .forEach((t) => tasksToBeAdded.push(t));

      setTasks(tasksToBeAdded);

      const awaitGeneratePage = async () => {
        const response = await generateExamsData();

        if (response?.status === "success") {
          setTimeout(() => {
            addBreakPage("#page", String(response.code_assessment));
            setIsLoading(false);
          }, 5000);
        }
      };

      awaitGeneratePage();
    });
    //TODO: verificar se o eslint está correto
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //useEffect(() => {
  //  examsForAssessment.forEach((e) => fetchTasksByExam(e));
  //}, [examsForAssessment]);

  return (
    <div className="preview_evaluation" style={{ position: "relative" }}>
      {isLoading && <LoadingPage />}

      <div className="area_button_actions">
        <Button
          id="btnPrint"
          variant="contained"
          color="primary"
          startIcon={<PrintIcon />}
          onClick={() => window.print()}
        >
          Gerar PDF
        </Button>

        <Button
          id="btnPrint"
          variant="contained"
          color="secondary"
          startIcon={<CloseIcon />}
          onClick={() => navigate.push("/editor/create/assessment")}
        >
          Fechar
        </Button>
      </div>

      <div className="print_assessment" id="page">
        <CoverEvaluation {...assessment} />

        {examsForAssessment.map((reader_exam) => (
          <Fragment key={`exam-item-${reader_exam.id_exam}`}>
            {reader_exam.list_tasks.map((reader_task) => {
              return reader_task ? (
                <Fragment key={`completed-task-${reader_task.id}`}>
                  <div
                    id={`task-${reader_task.id}`}
                    style={{ padding: "50pt" }}
                  >
                    {reader_task.content && (
                      <MarkdownRender
                        fit
                        components={components}
                        markdownCode={reader_task.content}
                      />
                    )}
                  </div>

                  {reader_task.items.map((itemTask, index) => {
                    numberItem += 1;

                    const selectSubContent = reader_task.subcontents.filter(
                      (subcontent) => subcontent.position === index + 1
                    );

                    return (
                      <>
                        {selectSubContent.length > 0 ? (
                          <div
                            id={`task-sub-content-${numberItem}`}
                            style={{
                              padding: "0 50pt",
                            }}
                          >
                            <MarkdownRender
                              fit
                              components={components}
                              markdownCode={selectSubContent[0].content}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        <ItemEvaluation
                          key={`item-${itemTask.id}`}
                          item={itemTask}
                          num_item={numberItem}
                          id_exam={reader_exam.id_exam}
                          id_assessment={reader_exam.id_assessment}
                        />
                      </>
                    );
                  })}
                </Fragment>
              ) : null;
            })}
          </Fragment>
        ))}
      </div>
    </div>
  );
}
