import { AssessmentExam, Exam } from "services/admin/Interfaces/Types";
import { getExams, removeExams } from "../../Actions/Exam/action";
import { registerAssessmentExam } from "../assessmentExam";
import ExamServices, { IAddTaskInExam } from "./exam.service";
import AlertService from "services/admin/AlertService";
import { callback } from "chart.js/dist/helpers/helpers.core";
import { resetClickedElements } from "../../Actions/treeView/actions";

export const registerExam = (props: Exam) => {
  return (dispatch: any) => {
    ExamServices.registerExam(props).then(({ data }: any) => {
      dispatch(getExams([data]));
    });
  };
};

export const registerExamAndWithAssessment = (
  props: Exam,
  assessmentId: number
) => {
  return (dispatch: any) => {
    ExamServices.registerExam(props).then(({ data }: any) => {
      dispatch(registerAssessmentExam(data.id, assessmentId));
      dispatch(getExams([data]));
    });
  };
};

export const findExams = (props?: any, callbackFunction?: any) => {
  return (dispatch: any) => {
    ExamServices.getAllExams(props).then(({ data }: any) => {
      const exams: Exam[] = data.results;
      dispatch(getExams(exams));
      if (callbackFunction) callbackFunction();
    });
  };
};

export const findExamById = (id: number) => {
  return (dispatch: any) => {
    ExamServices.getExamById(id).then(({ data }: any) => {
      dispatch(getExams([data]));
    });
  };
};
export const processExamUpdate = (props: Exam) => {
  return (dispatch: any) => {
    ExamServices.updateExam(props)
      .then(({ data }: any) => {
        dispatch(getExams([data]));
        AlertService.showAlert({
          message: "Tarefa salva com sucesso!",
          type: "success",
        });
      })
      .catch((e) => {
        AlertService.showAlert({
          message: "Erro ao salvar a tarefa!",
          type: "error",
        });
      });
  };
};

export const processExamDelete = (
  props: Exam,
  successCallBack: any,
  errorCallBack: any
) => {
  return (dispatch: any) => {
    ExamServices.deleteExam(props)
      .then(({ data }: any) => {
        dispatch(removeExams(props.id as number));
        dispatch(resetClickedElements());
        successCallBack();
      })
      .catch(() => {
        errorCallBack();
      });
  };
};

export const addTaskInExam = (props: IAddTaskInExam) => {
  return (dispatch: any) => {
    ExamServices.addTaskInExam(props)
      .then(({ data }: any) => {
        dispatch(findExamById(data.exam));
        AlertService.showAlert({
          message: "Tarefa adicionada com sucesso.",
          time: 5000,
          type: "success",
        });
      })
      .catch(() => {
        AlertService.showAlert({
          message: "Tarefa não adicionada",
          time: 5000,
          type: "erro",
        });
      });
  };
};
