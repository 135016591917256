import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { Box, CircularProgress, Typography } from "@mui/material";

import { TreeView, TreeItem } from "@mui/x-tree-view";
import {
  Assessment,
  Content,
  Exam,
  Item,
  ItemBank,
  Task,
} from "services/admin/Interfaces/Types";

import { ReactComponent as IconTask } from "assets/treeViewIcons/menu_book.svg";
import { ReactComponent as IconItem } from "assets/treeViewIcons/pencil.svg";
import { ReactComponent as Prancheta } from "assets/treeViewIcons/assignment.svg";
import { ReactComponent as Aplicacao } from "assets/treeViewIcons/bookmark.svg";
import { ReactComponent as Bank } from "assets/treeViewIcons/bank.svg";
import { ReactComponent as Clip } from "assets/treeViewIcons/clip.svg";

import { ReactComponent as MainAvaliacao } from "assets/treeViewIcons/main_avaliacao.svg";

import { ReactComponent as Enunciado } from "assets/treeViewIcons/enunciado.svg";

import TreeItemLabel from "./TreeItemLabel";

import { orderArrayById } from "utils/orderArrayById";

import {
  getExamsFromAssessment,
  getItemFromBank,
  getTasksFromBank,
  getTasksFromExam,
} from "../utils/elementGetters";

import {
  generateTaskContent,
  proccesAssessmentSelectionUtil,
  proccesBankSelectionUtil,
  proccesContentSelectionUtil,
  proccesExamSelectionUtil,
  proccesItemSelectionUtil,
  proccesRootElementSelectUtil,
  proccesTaskSelectionUtil,
  proccesTaskSubcontentSelectionUtil,
} from "../utils/treeUtils";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import "./tree_element.scss";
import { lineHeight } from "@mui/system";
import { findExams } from "store/reducers/Admin/AsyncActions/Exam";
import { getTasks } from "store/reducers/Admin/AsyncActions/Tasks";
interface ITreeView {
  treeFilterString: string;
  tabControl: string;
  setTabControl: any;
}

export default function ColapsibleTreeNav(props: ITreeView) {
  const dispatch = useDispatch();
  const { tabControl, setTabControl } = props;

  const [lastTermSearch, setLastTermSearch] = useState(
    undefined as string | undefined
  );

  const tasks: Task[] = useSelector((state: any) => state.admin).tasks;
  const exams: Exam[] = useSelector((state: any) => state.admin).exams;
  const assessments: Assessment[] = useSelector(
    (state: any) => state.admin
  ).assessments;
  const banks: ItemBank[] = useSelector((state: any) => state.admin).banks;
  const itensCreated: Item[] = useSelector((state: any) => state.admin).itens;
  const contents: Content[] = useSelector((state: any) => state.admin).contents;
  const [tasksOrdered, setTaskOrdered] = useState([] as Task[]);
  const [examsOrdered, setExamsOrdered] = useState([] as Exam[]);
  const [assessmentsOrdered, setAssessmentsOrdered] = useState(
    [] as Assessment[]
  );
  const [expanded, setExpanded] = useState([] as string[]);
  const [loadingNodes, setLoadingNodes] = useState([] as string[]);

  useEffect(() => {
    let copiaTasks: Task[] = [];
    tasks.forEach((t) => {
      const copia = { ...t };
      let itemsCopia: Item[] = [...t.items];
      itemsCopia.sort((a, b) => (a.number as number) - (b.number as number));
      copia.items = itemsCopia;
      copiaTasks.push(copia);
    });

    if (props.treeFilterString !== undefined && props.treeFilterString !== "") {
      setExpanded([
        "tasks_root",
        "provas_root",
        "aplicacao_root",
        "aula_root",
        "av_root",
        "bank_root",
      ]);
    } else {
      if (lastTermSearch !== props.treeFilterString) setExpanded([]);
    }
    setLastTermSearch(props.treeFilterString);
    setTaskOrdered(orderArrayById(copiaTasks));
    setExamsOrdered(orderArrayById(exams));
    setAssessmentsOrdered(orderArrayById(assessments));
  }, [tasks, exams, assessments, props.treeFilterString]);

  const proccesRootElementSelect = () => {
    proccesRootElementSelectUtil(dispatch);
  };

  const proccesTaskSelection = (task, examId) => {
    proccesTaskSelectionUtil(task, examId, dispatch);
  };

  const proccesTaskSubcontentSelection = (subcontent) => {
    proccesTaskSubcontentSelectionUtil(subcontent, dispatch);
  };

  const proccesItemSelection = (item) => {
    proccesItemSelectionUtil(item, dispatch);
  };

  const proccesExamSelection = (exam, prefix) => {
    addLoadingNode(prefix);
    if (exam)
      dispatch(
        getTasks({ exam_id: exam.id as number }, () =>
          removeLoadingNode(prefix)
        )
      );
    proccesExamSelectionUtil(exam, tasks, dispatch);
  };

  const proccesAssessmentSelection = (assessment, prefix) => {
    addLoadingNode(prefix);
    if (assessment)
      dispatch(
        findExams({ assessment_id: assessment.id as number }, () =>
          removeLoadingNode(prefix)
        )
      );
    proccesAssessmentSelectionUtil(assessment, dispatch);
  };

  const proccesBankSelection = (bank) => {
    proccesBankSelectionUtil(bank, dispatch);
  };

  const proccesContentSelection = (content) => {
    proccesContentSelectionUtil(content, dispatch);
  };

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  const addLoadingNode = (node) => {
    if (node && loadingNodes.find((n) => n === node) === undefined) {
      let nodes: string[] = [...loadingNodes, node];
      setLoadingNodes([...nodes]);
    }
  };

  const removeLoadingNode = (node) => {
    if (node) {
      let nodes: string[] = loadingNodes.filter((n) => n !== node);
      setLoadingNodes([...nodes]);
    }
  };

  const generateTasksTree = (
    tasks: Task[],
    nodePrefix: string,
    searchString: string | undefined,
    examId: number | undefined,
    fromBank: boolean
  ) => {
    const filteredTask = searchString
      ? tasks.filter((task) =>
          task.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : tasks;

    return filteredTask.map((task) => (
      <TreeItem
        className="item_nav"
        key={task.id}
        nodeId={nodePrefix + "_task_" + task.id}
        label={
          <TreeItemLabel
            element={task}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {task.id + " - " + task.title}
              </Typography>
            }
            icon={<Prancheta />}
            // icon={<IconTask />}
            redirectLink={
              fromBank
                ? "/editor/create/bank/" + (task.item_bank as number) + "/task"
                : "/editor/create/task/"
            }
            proccesClickFunction={(e) => proccesTaskSelection(e, examId)}
          />
        }
      >
        {generateTaskContent(task).map((content) => (
          <TreeItem
            className="item_nav"
            key={content.element.id}
            nodeId={
              nodePrefix + "_qts_" + content.type + "_" + content.element.id
            }
            label={
              <TreeItemLabel
                element={content.element}
                elemenLabel={
                  <Typography sx={{ color: "#365BDC" }}>
                    {(content.order != null ? content.order + " - " : "") +
                      content.description}
                  </Typography>
                }
                icon={content.type === "ITEM" ? <IconItem /> : <Enunciado />}
                redirectLink={content.link}
                proccesClickFunction={
                  content.type === "ITEM"
                    ? proccesItemSelection
                    : proccesTaskSubcontentSelection
                }
              />
            }
          ></TreeItem>
        ))}
      </TreeItem>
    ));
  };

  const generateItemBankTree = (
    items: Item[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredItem = searchString
      ? items.filter((item) =>
          item.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : items;

    return filteredItem.map((item) => (
      <TreeItem
        className="item_nav"
        key={item.id}
        nodeId={nodePrefix + "_qts_" + item.item_bank + "_" + item.id}
        label={
          <TreeItemLabel
            element={item}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {(item.id != null ? item.id + " - " : "") + item.title}
              </Typography>
            }
            icon={<Clip />}
            redirectLink={
              "/editor/create/bank/" + (item.item_bank as number) + "/item"
            }
            proccesClickFunction={proccesItemSelection}
          />
        }
      ></TreeItem>
    ));
  };

  const generateExamTree = (
    exams: Exam[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredExams = searchString
      ? exams.filter((exam) =>
          exam.description
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : exams;

    return filteredExams.map((exam) => (
      <TreeItem
        className="item_nav"
        key={exam.id}
        nodeId={nodePrefix + "_exam_" + exam.id}
        id={`${"exam_" + exam.id}`}
        endIcon={null}
        label={
          <TreeItemLabel
            element={exam}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {exam.id + " - " + (exam.title !== null ? exam.title : "Exame")}
              </Typography>
            }
            // icon={<Prancheta />}
            icon={<IconTask />}
            redirectLink="/editor/create/exam/"
            proccesClickFunction={(e) =>
              proccesExamSelection(e, nodePrefix + "_exam_" + exam.id)
            }
          />
        }
      >
        {loadingNodes?.find((id) => id === nodePrefix + "_exam_" + exam.id) !==
        undefined ? (
          <CircularProgress sx={{ marginLeft: "40%" }} size={30} />
        ) : (
          generateTasksTree(
            getTasksFromExam(exam, tasksOrdered),
            nodePrefix + exam.id,
            searchString,
            exam.id as number,
            false
          )
        )}
        {null}
      </TreeItem>
    ));
  };

  const generateAssessmentTree = (
    assesments: Assessment[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredAssessements = searchString
      ? assessments.filter((assessment) =>
          assessment.title
            .toLocaleLowerCase()
            .includes(searchString.toLocaleLowerCase())
        )
      : assesments;

    return filteredAssessements.map((assessment) => (
      <TreeItem
        className="item_nav"
        key={assessment.id}
        nodeId={nodePrefix + "_assessment_" + assessment.id}
        label={
          <TreeItemLabel
            element={assessment}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {assessment.id + " - " + assessment.title}
              </Typography>
            }
            icon={<Aplicacao />}
            redirectLink="/editor/create/assessment/"
            proccesClickFunction={(e) =>
              proccesAssessmentSelection(
                e,
                nodePrefix + "_assessment_" + assessment.id
              )
            }
          />
        }
      >
        {loadingNodes?.find(
          (id) => id === nodePrefix + "_assessment_" + assessment.id
        ) !== undefined ? (
          <CircularProgress sx={{ marginLeft: "40%" }} size={30} />
        ) : (
          generateExamTree(
            getExamsFromAssessment(assessment, assessment.exams, examsOrdered),
            nodePrefix + assessment.id,
            searchString
          )
        )}
        {null}
      </TreeItem>
    ));
  };

  const generateBankTree = (
    itemsBank: ItemBank[],
    nodePrefix: string,
    searchString: string | undefined
  ) => {
    const filteredItemsBank = searchString
      ? itemsBank.filter((ib) =>
          ib.name.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
        )
      : itemsBank;

    return filteredItemsBank.map((bank) => (
      <TreeItem
        className="item_nav"
        key={bank.id}
        nodeId={nodePrefix + "_assessment_" + bank.id}
        label={
          <TreeItemLabel
            element={bank}
            elemenLabel={
              <Typography sx={{ color: "#365BDC" }}>
                {bank.id + " - " + bank.name}
              </Typography>
            }
            icon={<Bank />}
            redirectLink="/editor/create/itembank/"
            proccesClickFunction={proccesBankSelection}
          />
        }
      >
        {generateTasksTree(
          getTasksFromBank(bank, tasksOrdered),
          nodePrefix + bank.id,
          searchString,
          undefined,
          true
        )}

        {generateItemBankTree(
          getItemFromBank(bank, itensCreated),
          nodePrefix + bank.id,
          searchString
        )}
      </TreeItem>
    ));
  };

  const handleChange = (event, newValue) => {
    setTabControl(newValue);
  };

  return (
    <Box maxWidth={"100%"} maxHeight={"100%"} paddingLeft={2}>
      <TabContext value={tabControl}>
        <TabList
          onChange={handleChange}
          sx={{
            ".Mui-selected": {
              background: "white !important",
            },
          }}
        >
          <Tab
            label="Avaliações"
            sx={{
              width: "50%",
              fontWeight: "600",
              fontSize: "13px",
              lineHeight: "15px",
              textTransform: "none",
              padding: "0",
              borderBottom: "3px solid #DEE1E8",
            }}
            value="1"
          />
          <Tab
            label="Banco de Tarefas/Itens"
            sx={{
              width: "50%",
              fontWeight: "600",
              fontSize: "13px",
              lineHeight: "15px",
              textTransform: "none",
              padding: "0",
              borderBottom: "3px solid #DEE1E8",
            }}
            value="2"
          />
        </TabList>

        <TabPanel value="1" sx={{ paddingLeft: "0" }}>
          <TreeView
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            aria-label="rich object"
            defaultCollapseIcon={<ExpandLess />}
            defaultExpandIcon={<ExpandMore />}
            sx={{
              height: "72vh",
              overflowX: "auto",
              overflowy: "scroll",
            }}
            className="box-tree"
          >
            <TreeItem
              className="root_tree_element"
              key={"av_root"}
              nodeId={"av_root"}
              label={
                <TreeItemLabel
                  element={undefined}
                  elemenLabel={
                    <Typography sx={{ color: "var(--primary-regular)" }}>
                      Avaliações
                    </Typography>
                  }
                  icon={<MainAvaliacao />}
                  redirectLink="/editor"
                  proccesClickFunction={() =>
                    proccesAssessmentSelection(undefined, undefined)
                  }
                />
              }
            >
              {generateAssessmentTree(
                assessmentsOrdered,
                "root_assessment",
                props.treeFilterString
              )}
            </TreeItem>
          </TreeView>
        </TabPanel>
        <TabPanel value="2" sx={{ paddingLeft: "0" }}>
          <TreeView
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            aria-label="rich object"
            defaultCollapseIcon={<ExpandLess />}
            defaultExpandIcon={<ExpandMore />}
            sx={{
              height: "72vh",
              overflowX: "auto",
              overflowy: "scroll",
            }}
          >
            <TreeItem
              className="root_bank_element"
              key={"bank_root"}
              nodeId={"bank_root"}
              label={
                <TreeItemLabel
                  element={undefined}
                  elemenLabel={
                    <Typography sx={{ color: "var(--primary-regular)" }}>
                      Tarefas & Itens
                    </Typography>
                  }
                  icon={<Clip />}
                  redirectLink="/editor"
                  proccesClickFunction={() =>
                    proccesAssessmentSelection(undefined, undefined)
                  }
                />
              }
            >
              {generateBankTree(banks, "root_bank", props.treeFilterString)}
            </TreeItem>
          </TreeView>
        </TabPanel>
      </TabContext>
    </Box>
  );
}
