export interface Choice {
  id?: number;
  item?: number;
  content: string;
  correct?: boolean;
}

export interface Item {
  id?: number;
  item_bank?: number;
  task?: number;
  title: string;
  content: string;
  choices: Choice[];
  number: number | undefined | null;
  is_hybrid?: boolean;
  is_public?: boolean;
}

export interface Task {
  id?: number;
  content: string;
  title: string;
  items: Item[];
  subcontents?: TaskSubContent[];
  is_public?: boolean;
  item_bank?: number;
}

export interface ItemBank {
  id?: number;
  name: string;
  created_by?: string;
  updated_in?: Date;
}

export interface TaskFilters {
  ability?: string;
  knowledge?: string;
  knowledge_area?: string;
  title?: string;
  id?: number;
  limit?: number;
  exam_id?: number;
}

export interface TaskSubContent {
  id?: number;
  task: number;
  content: string;
  position: number;
  title: string;
}

export interface Ability {
  id?: number;
  sub_abilities?: [];
  code: string;
  name: string;
  description: string;
  knowledge?: number;
  knowledgeName?: string;
  parent?: number;
  parentName?: string;
  series?: SimpleAbilitySerie[];
}

export interface AbilityEdge {
  id?: number;
  from_ability: Ability;
  to_ability: Ability;
}

export interface KnowledgeArea {
  id?: number;
  abilities?: Ability[];
  created_by?: string;
  code: string;
  name: string;
}

export interface Knowledge {
  id?: number;
  knowledge_area: number;
  knowledgeAreaName?: string;
  code: string;
  name: string;
}

export interface TaskExam {
  id?: number;
  number: number;
  task: number;
  exam?: number;
  taskObj?: Task;
}

export interface Exam {
  id?: number;
  tasks: TaskExam[];
  student_exam?: [];
  code: string;
  knowledge_area?: number;
  created_by?: number;
  description: string;
  title?: string;
  published: boolean;
}

export interface AssessmentExam {
  id?: number;
  assessment?: number;
  exam: number;
  examObj?: Exam;
}

export interface Assessment {
  id?: number;
  title: string;
  exams: AssessmentExam[];
  code: string;
  description: string;
  created_by?: number;
  starts_at: string;
  ends_at: string;
  exam_time: number;
  created_at?: string;
  modified_at?: string;
  min_transcribers?: number;
  min_evaluators?: number;
}

export interface Content {
  id?: number;
  title?: string;
  subcontent_type: "task" | "item" | "subcontent" | "content";
  parent?: number;
  position?: number;
  object?: any;
  text?: string;
  subcontents?: Content[];
  created_by?: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface ItemFormData {
  id?: number;
  title: string;
  description: string;
  itemType: "H" | "M" | "A";
  taskId: number | undefined;
  choices: Choice[];
  number: number | undefined | null;
  is_hybrid: boolean;
  is_public?: boolean;
}

export interface TaskFormData {
  id?: number;
  description: string;
  title: string;
  items?: Item[];
  is_public?: boolean;
  item_bank?: number;
}

export interface ExamFormData {
  id?: number;
  tasks: TaskExam[];
  title?: string;
  code: string;
  knowledge_area?: number;
  created_by?: number;
  description: string;
  published: boolean;
}

export interface AssessmentFormData {
  id?: number;
  exams: AssessmentExam[];
  title: string;
  code: string;
  description: string;
  starts_at: string;
  ends_at: string;
  exam_time: number;
  created_at?: string;
  modified_at?: string;
  created_by?: number;
  min_transcribers?: number;
  min_evaluators?: number;
}

export interface ContentFormData {
  id?: number;
  title?: string;
  subcontent_type: "task" | "item" | "subcontent" | "content";
  parent?: number;
  position?: number;
  object?: any;
  text?: string;
  subcontents?: Content[];
  created_by?: number;
  created_at?: Date;
  updated_at?: Date;
}

export interface ItemBankFormData {
  id?: number;
  name: string;
  created_by?: string;
  updated_in?: Date;
}

export interface SelectedElement {
  elementType:
    | ElementTypes.ITEM
    | ElementTypes.TASK
    | ElementTypes.EXAM
    | ElementTypes.ASSESSMENT
    | ElementTypes.TASK_SUBCONTENT
    | ElementTypes.CONTENT
    | ElementTypes.ITEM_BANK
    | undefined;
  element:
    | Item
    | Task
    | Exam
    | Assessment
    | TaskSubContent
    | Content
    | ItemBank
    | undefined;
  elementDataFormInitial:
    | ItemFormData
    | TaskFormData
    | ExamFormData
    | AssessmentFormData
    | TaskSubContent
    | ContentFormData
    | ItemBankFormData
    | undefined;
  elementDataForm:
    | ItemFormData
    | TaskFormData
    | ExamFormData
    | AssessmentFormData
    | TaskSubContent
    | ContentFormData
    | ItemBankFormData
    | undefined;
  parentElement?: number | ItemBank;
}

export enum ElementTypes {
  ITEM = "ITEM",
  TASK = "TASK",
  EXAM = "EXAM",
  ASSESSMENT = "ASSESSMENT",
  TASK_SUBCONTENT = "TASK_SUBCONTENT",
  CONTENT = "CONTENT",
  ITEM_BANK = "ITEM_BANK",
}

export interface Serie {
  id: number;
  name: string;
}

export interface AbilitySerie {
  id?: number;
  ability: number;
  serie: number;
}

export interface SimpleAbilitySerie {
  id?: number;
  serie_id: number;
  serie_name: string;
}
export interface Page<T> {
  count: number;
  next: string;
  previous: string;
  results: T[];
}

export interface TaskSubContentFormData {
  id?: number;
  task: number;
  content: string;
  position: number;
  title: string;
}

export interface EditorModalState {
  open: boolean;
  text: string;
  attachedTo: string;
  saveText: string | null;
}

export type FormData =
  | TaskSubContent
  | ItemFormData
  | TaskFormData
  | ExamFormData
  | AssessmentFormData;

export interface IReorderItemRequest {
  id: number;
  number: number;
}
export interface IReorderRequest {
  items: IReorderItemRequest[];
}
